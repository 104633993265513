import React, { useState, useEffect, useRef, useMemo } from "react";
import style from "./todaysAppointment.module.css";
import AvailabilityCard from "./availabilityCard";
import routes from "../../routes";
import { useGlobalStateContext } from "../../contexts/GlobalContext";
import { Link } from "react-router-dom/cjs/react-router-dom";
import currLocationImg from "../../assets/images/useCurrentLoc.svg"
import { paramsOnLoad, saveToSession } from "../../utils";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useTodaysLocations from "../../hooks/useTodaysLocations.js";
import LocationIcon from "../../assets/icons/locationIcon.svg";
import axios from "axios";
import { API_DOMAIN, SESSION_STORAGE_KEYS, AZURE_DOMAIN } from "../../const.js";
import cloneDeep from "lodash/cloneDeep";
import useOutsideClick from "../../hooks/useOutsideClick.js";
import { sortBy } from "lodash";
import Loader from "../../components/generics/Loader.jsx";
import { validateAppointmentSlot, validateAppointmentType, validateClient } from "../../validations/index.js";
import useValidation from "../../hooks/useValidation.js";
import useSWR from "swr";

export default function TodaysAppointment() {

  const {
    state,
    setAppointmentType,
    setIsTodaysAppointment,
    setLocations,
    setAppointmentSlot,
    setAppointmentReason,
    setCareAndTypesData,
    setAppointmentReasonOtherText
  } = useGlobalStateContext();
  const { email, searchAddress, appointmentType, selectedAppointmentSlot, selectedLocation, appointmentTypeId } = state;
  const [errorState, setErrorState] = useState({ type: false });
  const isTodaysAppointment = paramsOnLoad.get("isToday");

  const [searchLat, setSearchLat] = useState("");
  const [searchLong, setSearchLong] = useState("");
  const [apptType, setApptType] = useState("Sick");
  const [loading, topLocations, topLocationsStatus] = useTodaysLocations(
    searchAddress,
    appointmentType
  );
  const getPostal = paramsOnLoad.get("searchAddress");
  const [appointmentLocations, setAppointmentLocations] = useState([]);
  const sortByOptions = [
    { name: "Closest Location", value: "closest" },
    { name: "Earliest Appointment", value: "soonest" },
  ];
  const [appointmentLocationsStatus, setAppointmentLocationStatus] =
    useState("idle");
  const [isAppointmentSlotLoading, setIsAppointmentSlotLoading] =
    useState(false);
  const ongoingFetch = useRef(null);
  const getUserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const location = state.selectedLocation;
  const [selectedSort, setSelectedSort] = useState(sortByOptions[0]);
  const [providers, setProviders] = useState([]);
  const [preferredProvider, setPreferredProvider] = useState(null);
  const ref = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const slotLocationSelected = selectedAppointmentSlot && selectedLocation && Object.keys(selectedAppointmentSlot).length > 0 &&
    Object.keys(selectedLocation).length > 0;;
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "us" },
    },
    debounce: 100,
  });
  useValidation({
    checkPrerequisiteState(state) {
      const isTypeSet = validateAppointmentType(state);
      const isAppointmentSelected = validateAppointmentSlot(
        state.selectedAppointmentSlot
      );
      if (isTypeSet.error || isAppointmentSelected.error)
        sessionStorage.removeItem("selectedPatients");
      setErrorState({ type: true });

      return isTypeSet;

    },
    handlePageValidation(state) {
      const hasClientErrors = validateClient(state);
      if (hasClientErrors.error) {
        setErrorState(hasClientErrors.errors);
      }
      return hasClientErrors;
    },
    handleLinkUpdates() {
      return {
        completedPercent: 0,
        back: { enabled: false },
        next: {
          url: slotLocationSelected ? state.userIsAuthed ? routes.petSelect : email ? routes.petSelect : routes.signIn : '#',
          enabled: slotLocationSelected ? true : false && state.appointmentTypes.length,
        },
      };
    },
    watch: state,
  });

  useOutsideClick(ref, () => {
    clearSuggestions();
  });

  useEffect(() => {
    if (searchAddress) {
      setLocations({ searchAddress: searchAddress });
      setValue(searchAddress, false);
    }
    else if (getPostal) {
      setLocations({ searchAddress: getPostal });
      setValue(searchAddress, false);
    }
  }, [getPostal])

  useEffect(() => {
    if (!searchAddress && !getPostal) {
      getCurrentLocationHandler();
    }
  }, [searchAddress])

  const { errorData } = useSWR("/v1/appointment-types", (endpoint) => {
    // This first call helps us to understand if we've authed the user recently
    setIsLoading(true);

    return axios.get(`${API_DOMAIN}${endpoint}`).then((data) => {
      setIsLoading(false);

      const payload = data.data.reduce(
        (acc, curr) => {
          const splitNames = curr.name.split(" - ");
          if (splitNames.length === 2) {
            acc.careTypes.push({
              name: splitNames[1],
              value: curr.id,
              duration: curr.duration,
            });
          } else {
            // this isn't included in the current booking tool, so we skip it completely
            if (curr.name === "Stay") return acc;

            acc.appointmentTypes.push({
              name: curr.name,
              value: curr.name.toLowerCase(),
              vetspireId: curr.id,
              duration: curr.duration,
            });
          }

          return acc;
        },
        {
          careTypes: [],
          appointmentTypes: [
            { name: "Care", value: "care", vetspireId: "" },
          ],
        }
      );

      const sortedCareTypes = sortBy(payload.careTypes, ["name"]);
      saveToSession(SESSION_STORAGE_KEYS.CARE_REASONS, sortedCareTypes);
      saveToSession(SESSION_STORAGE_KEYS.APPOINTMENT_TYPES, payload.appointmentTypes);
      setCareAndTypesData({
        careTypes: sortedCareTypes,
        appointmentTypes: payload.appointmentTypes,
      });
    });
  }, {
    revalidateOnFocus: false,  // Prevent re-fetch on window focus
    revalidateOnReconnect: false,  // Prevent re-fetch on network reconnect
    refreshInterval: 0,  // Disable automatic refreshing
  });

  useEffect(() => {
    if (isTodaysAppointment) {
      setIsTodaysAppointment({ isTodaysAppointment: true });
    }
  }, [])

  useEffect(() => {
    if (appointmentTypeId && !isLoading) {
      setAppointmentType({
        type: "care",
        id: appointmentTypeId,
      });

    }
    else {
      setAppointmentType({
        type: "care",
        id: apptType === "Sick" ? "236" : "235",
      });

    }

  }, [apptType, appointmentTypeId])

  useEffect(() => {
    setValue(searchAddress, false);
  }, [searchAddress, setValue]);
  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };


  const getCurrentLocationHandler = () => {
    if (navigator?.geolocation) {
      navigator?.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };


  function showPosition(position) {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      if (latitude && longitude) {
        setSearchLat(latitude);
        setSearchLong(longitude);
      }

      let geocoder;
      geocoder = new window.google.maps.Geocoder();
      let latlng = new window.google.maps.LatLng(latitude, longitude);
      setAppointmentSlot({ location: {}, appointment: {} })
      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const add = results[0].formatted_address;
            const value = add.split(",");
            const count = value.length;
            const city = value[count - 3];

            for (let i = 0; i < results[0].address_components.length; i++) {
              let postalCode = results[0].address_components[i].types[0];

              if (postalCode === "postal_code") {
                let postal_code = results[0].address_components[i]["long_name"];

                if (postal_code) {
                  setLocations({ searchAddress: postal_code });
                }
              }
            }
          } else {
            //x.innerHTML = "address not found";
            console.log("address not found");
          }
        } else {
          //x.innerHTML = "Geocoder failed due to: " + status;
          console.log("Geocoder failed due to");
        }
      });
    });
  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.")
        setLocations({ searchAddress: "Philadelphia" });
        setValue(searchAddress, false);
        setAppointmentSlot({ location: {}, appointment: {} })
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.")
        setLocations({ searchAddress: "Philadelphia" });
        setValue(searchAddress, false);
        setAppointmentSlot({ location: {}, appointment: {} })
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.")
        setLocations({ searchAddress: "Philadelphia" });
        setValue(searchAddress, false);
        setAppointmentSlot({ location: {}, appointment: {} })
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.")
        setLocations({ searchAddress: "Philadelphia" });
        setValue(searchAddress, false);
        setAppointmentSlot({ location: {}, appointment: {} })
        break;
    }
  }

  const handleSelect =
    ({ description }) =>
      () => {
        setValue(description, false);
        clearSuggestions();

        setAppointmentSlot({ location: {}, appointment: {} })
        setLocations({ searchAddress: description });
        setAppointmentLocations([]);

        // enabled lat long for calculating walk-in distance
        getGeocode({ address: description }).then((results) => {
          const { lat, lng } = getLatLng(results[0]);
          setSearchLat(lat);
          setSearchLong(lng);

          setLocations({ searchAddress: description });
        });
      };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        description,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      const descriptionLength = description.length;

      return (
        <li
          className={`${style.customeMedia}`}
          key={place_id}
          onClick={handleSelect(suggestion)}
          style={{ cursor: "pointer" }}
        >
          <div className={`${style.customeMediaImg}`} style={{ paddingTop: "1px" }}>
            <img src={LocationIcon} />
          </div>
          <div className={`${style.customeMediaBody}`}>
            <div className="body_part">
              <p style={{ paddingBottom: "0px", fontSize: "13px" }}>
                {main_text} &nbsp;
                <span
                  title={description}
                  style={{
                    fontSize: "12px",
                    color: "#a2a2a2",
                    lineHeight: "17px",
                    paddingRight: "5px",
                  }}
                >
                  {descriptionLength <= 40
                    ? description
                    : description?.substr(0, 40) + "..."}
                </span>
              </p>
            </div>
          </div>
        </li>
      );
    });

  function getAppointments(
    apptType,
    apptTypeId = 236,
    locations = 567,
  ) {
    const devLoc = [268, 267, 152];
    const devLocId = devLoc.join(",");
    const locationId = locations
      .map((m) => m.meta.locations_meta_vetspire_id)
      .join(",");
    const americaTimeZone = 'America/Los_Angeles';
    let endpoint = `${API_DOMAIN}/v1/todays-appointments?apptTypeId=${appointmentTypeId}&timeZone=${getUserTimezone}&locationId=${AZURE_DOMAIN == ('https://devapis.heartandpaw.com' || 'https://uatapis.heartandpaw.com') ? devLocId : locationId}`;
    return axios.get(endpoint).then((res) => res.data);
  }

  async function getAppointmentTimes(locations) {
    try {
      setAppointmentLocationStatus("loading");
      const currentFetch = getAppointments(
        state.appointmentType,
        state.appointmentTypeId,
        locations,
      );
      ongoingFetch.current = currentFetch;

      setIsAppointmentSlotLoading(true);
      // setAppointmentLocations([]);

      const json = await new Promise((res, rej) => {
        currentFetch.then((data) => {

          if (currentFetch === ongoingFetch.current) {

            ongoingFetch.current = null;

            res(data);
            setIsAppointmentSlotLoading(false);
          } else {
            rej("Not current fetch anymore!");
          }
        });
      }).catch((err) => {
        console.warn(err);
      });

      if (json) {
        const mappedLocations = json?.appointments?.map((loc, idx) => {
          const wpLoc = locations.find(
            (l) => l.meta.locations_meta_vetspire_id === loc.id
          );
          loc.wp = wpLoc; //locations[idx];
          return loc;
        });
        let r = mappedLocations;
        const obj = {};
        mappedLocations?.forEach((appt) => {
          const slotArr = Object?.values(appt?.availableSlots);
          const isAvailableAppt = slotArr?.some((el) => el.slots.length > 0);

          if (obj[appt?.id]) {
            if (isAvailableAppt) {
              obj.apptData.push(appt?.id);
            } else {
              const index = obj.apptData.indexOf(appt.id);
              if (index !== -1) {
                obj.apptData.splice(index, 1);
              }
            }
          }
        });
        setAppointmentLocations(r);
        setAppointmentLocationStatus("ready");
      }
    } catch (err) {
      console.error("the error is....");
      console.error(err);
      setAppointmentLocations([]);
      setAppointmentLocationStatus("error");
    }
  }

  const hasTopLocations = topLocations?.length;
  useEffect(() => {
    if (topLocations?.length) {
      getAppointmentTimes(topLocations);
    } else {
      setAppointmentLocations([]);
      setAppointmentLocationStatus("cant-search");
    }
  }, [
    // searchAddress,
    topLocations,
    appointmentTypeId
  ]);

  const memoizedAppointments = useMemo(() => {
    let sortedAppointments = cloneDeep(appointmentLocations)
      .map((l) => {
        if (!preferredProvider || preferredProvider?.value === null) return l;

        if (appointmentType === "care") {
          const dates = Object.keys(l.availableSlots);
          dates.forEach((DATE_KEY) => {
            l.availableSlots[DATE_KEY].slots = l.availableSlots[DATE_KEY].slots.filter(
              (slot) => slot.availableAppointments[preferredProvider.id]
            );
            // Remove the date if no slots are available
            if (!l.availableSlots[DATE_KEY].slots.length) delete l.availableSlots[DATE_KEY];
          });
        }
        return l;
      });

    // Sorting logic based on available slots, urgent care, and distance
    sortedAppointments.sort((a, b) => {
      const hasAvailableSlotsA = Object.keys(a.availableSlots).some(
        date => a.availableSlots[date].slots.length > 0,
      );
      const hasAvailableSlotsB = Object.keys(b.availableSlots).some(
        date => b.availableSlots[date].slots.length > 0,
      );

      // 1. Appointments with available slots come first.
      if (hasAvailableSlotsA && !hasAvailableSlotsB) return -1;
      if (!hasAvailableSlotsA && hasAvailableSlotsB) return 1;

      // 2. Appointments without slots but with urgent care.
      if (!hasAvailableSlotsA && a.isUrgentVetCare && !(b.isUrgentVetCare)) return -1;
      if (!hasAvailableSlotsB && b.isUrgentVetCare && !(a.isUrgentVetCare)) return 1;


      // Lastly: Non-urgent care centers without available slots, sorted by distance
      return a.wp.distance - b.wp.distance;
    });

    return sortedAppointments;
  }, [preferredProvider, appointmentLocations, selectedSort]);

  const hasAppointments = memoizedAppointments?.reduce((acc, curr) => {
    return acc || Object.keys(curr.availableSlots);
  }, false);

  if (isLoading) {
    return (
      <div className={`${style.loaderContainer}`}>
        <Loader />
      </div>
    );
  }

  if (errorData)
    return (
      <div className={`${style.loaderContainer}`}>
        <div className="text-center">
          <h2 className="text-5xl text-red-700 font-bold">Uh oh.</h2>
          <p>We've encountered an error..</p>
          <p className="font-bold pt-2">
            <Link to={routes.type}>Refresh to try again.</Link>
          </p>
        </div>
      </div>
    );
  return (
    <>
      <div className={style.page}>

        <div className="min-h-screen bg-gray-200 p-8">
          <div className="mx-auto">
            <div className={`${style.parent} mb-8 flex justify-between`}>
              <div className={`${style.reasonVisit}`}>
                <p>Reason for Visit</p>
                <div className={`${style.users_btn}`}>
                  <button
                    onClick={() => {
                      setAppointmentType({
                        type: "care",
                        id: "236",
                      });
                      sessionStorage?.removeItem("selectedPatients")
                      setAppointmentReason({ appointmentReason: null });
                      setAppointmentReasonOtherText({
                        appointmentReasonOtherText: null,
                      });
                      setAppointmentSlot({ location: {}, appointment: {} })
                    }}
                    className={`${style.u_btn} ${appointmentTypeId === '236' ? style.returning_active : style.u_btn}  py-2 px-4`}
                  >
                    Sick
                  </button>

                  <button className={`${style.u_btn} ${appointmentTypeId === '235' ? style.returning_active : style.u_btn} py-2 px-4`}
                    onClick={() => {
                      setAppointmentType({
                        type: "care",
                        id: "235",
                      }
                      );
                      sessionStorage?.removeItem("selectedPatients")
                      setAppointmentReason({ appointmentReason: null });
                      setAppointmentReasonOtherText({
                        appointmentReasonOtherText: null,
                      });
                      setAppointmentSlot({ location: {}, appointment: {} })
                    }}>
                    Wellness
                  </button>

                </div>
              </div>
              <div className={`${style.location_center_parent} flex`}>
                <div className="centerParent" ref={ref} >
                  <div className="w-auto"><p className={`${style.headingStyle} font-semibold`}>Find centers near</p></div>
                  <div className={`${style.dropdownParent}`}>
                    <input
                      className={` ${style.inputFix}  md:max-w-xl rounded-lg border-2 border-solid border-gray-400 cus_input w-30 p-4 border border-gray-300 rounded-md `}
                      value={value}
                      onChange={handleInput}
                      disabled={!ready}
                      placeholder="Enter city, state or ZIP"
                      type="search"
                      onKeyUp={event => {
                        if (event.key === 'Enter' && (!isNaN(event.target.value)) && event.target.value.length == 5) {
                          setLocations({ searchAddress: event.target.value });
                          setAppointmentSlot({ location: {}, appointment: {} })
                          clearSuggestions();
                        }
                      }}
                    />
                    {status === "OK" && (

                      <div className={` ${style.locationDropdown}`} style={{ zIndex: "1" }}>
                        <ul
                          className={` ${style.cusDropdown}`}
                          style={{
                            height: "100%",
                            overflowY: "auto",
                            marginBottom: "200px",
                            width: "100%",
                            boxShadow:
                              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                          }}
                        >
                          {renderSuggestions()}
                        </ul>
                      </div>

                    )}
                  </div>
                  {/* <input
          className="w-30 p-4 border border-gray-300 rounded-md"
        /> */}
                </div>
                <div className={`${style.currLocParent} ml-3 mt-10`}>
                  <div
                    onClick={() => getCurrentLocationHandler()}
                    className={`${style.use_my_location} flex leading-5 underline`}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={currLocationImg} className="mr-2" alt="use-my-current-location" />Use my Current Location</div>
                </div>
              </div>
            </div>

            {isAppointmentSlotLoading && (
              <div className="book-appoiment-loader" style={{ margin: windowWidth < 768 ? "0px 30px 0px 15px" : "0px 35px 50px 35px" }}>
                <div className="snippet" style={{ height: "280px" }}>
                  <div className="stage" style={{ margin: "0px" }}>
                    <p className="paratext" style={{ bottom: "75px" }}>Looking for appointments </p>

                    <div className="stage_inner">
                      <div className="dot-spin"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isAppointmentSlotLoading && <div className={`${style.cardsContainer} grid grid-cols-3 gap-8 g-0`}>
              {/* {centers.map(center => ( */}
              <AvailabilityCard
                memoizedAppointments={memoizedAppointments}
                location={location}
                topLocationsStatus={topLocationsStatus}
                hasTopLocations={hasTopLocations}
                onClick={(appointment, location) =>
                  setAppointmentSlot({ location, appointment })
                }
                selectedAppointmentSlot={selectedAppointmentSlot}
                selectedLocation={selectedLocation}
                windowWidth={windowWidth}
                hasAppointments={hasAppointments}
                dataAppointmentLocations={appointmentLocations}
                appointmentType={state.appointmentType}
                searchAddress={searchAddress}
              // key={center.id} center={center} 
              />
              {/* ))} */}
            </div>}
          </div>
        </div>
      </div>
      <div className="pagination-container">
        <div className="h-16 fixed flex space-x-4 justify-center items-center left-0 p-4 bottom-0 border-t-2 border-gray-200 border-solid bg-white w-full">
        </div>

      </div>
    </>
  );
}
